@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Montserrat:wght@700&display=swap');

.ql-video {
  max-width: 670px;
  width: 100%;
  height: 320px;
}

.reactQuillBounds .ql-stroke {
  stroke: #4A5568;
}

.reactQuillBounds .ql-fill {
  fill: #4A5568;
}