.react-calendar {
  /*background-color: #add;*/
  /*background-color: #dadada;*/
  max-width: 100%;
  color: #222;
  border-radius: 8px;
  border: 1px solid #bbb;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 10px;
}
.react-calendar__navigation {
  /*background-color: #abb;*/
  margin: 15px;
  text-align: center;
}
.react-calendar__navigation button {
  /*color: #6f48eb;*/
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */

.react-calendar__month-view__days__day--neighboringMonth {
  /*background: #eee;*/
  opacity: 30%;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  /*text-transform: uppercase;*/
}
.react-calendar__tile {
  margin-bottom: 8px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /*border-radius: 6px;*/
  /*background: #f8f8fa;*/
  /*color: #6f48eb;*/
}
.react-calendar__tile--now {
  /*background: #6f48eb33;*/
  /*border-radius: 6px;*/
  /*font-weight: bold;*/
  /*color: #6f48eb;*/
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /*background: #6f48eb33;*/
  /*border-radius: 6px;*/
  /*font-weight: bold;*/
  /*color: #6f48eb;*/
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  /*background: #f8f8fa;*/
}
.react-calendar__tile--active {
  /*background: #6f48eb;*/
  /*border-radius: 6px;*/
  /*font-weight: bold;*/
  /*color: white;*/
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /*background: #6f48eb;*/
  /*color: white;*/
}
.react-calendar--selectRange .react-calendar__tile--hover {
  /*background-color: #f8f8fa;*/
}
