.icon-yellow {
  stroke: #a0aec0;
}
.icon-yellow:hover {
  stroke: #fdb619;
}

.week-min-height {
  min-height: 400px;
}

.chakra-modal__body a {
  color: #fdb619;
}
.chakra-modal__body a:hover {
  text-decoration: underline;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* fixes outline in safari */
.accordion__heading:focus,
.accordion__button:focus {
  outline: none;
}

/* makes sure background can't be scrolled when modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

/* quill editor */
.quill {
  background: #fff;
}
.ql-editor {
  min-height: 250px;
  max-height: 500px;
}

.quill .ql-container {
  font-size: 16px;
}

/* text fade out */
.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 4s;
}

@keyframes fadeIt {
  0% {
    background-color: #fafafa;
  }
  50% {
    background-color: #ffe6b5;
  }
  100% {
    background-color: #fafafa;
  }
}

.backgroundAnimated {
  animation: fadeIt 1s ease-in-out;
}

/* update banner */
#new-updates {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 5px;
  padding: 8px;
  background-color: #fdb619;
  color: white;
  z-index: 1;
}
#new-updates.show {
  display: block;
}

#new-updates:hover {
  background-color: #f9ac04;
}

/* react select input */
input[type='text']:focus {
  box-shadow: none;
}

.tourWrapper {
  max-width: 520px !important;
}

.chakra-text ol {
  list-style: decimal;
  padding-inline: 40px;
}

.chakra-text ul {
  list-style: initial;
  padding-inline: 40px;
}

.chakra-text a {
  color: #fdb619;
}

.chakra-text a:hover {
  text-decoration: underline;
}
